<script setup lang="ts">

    declare const tingle: any;

    const { locale } = useI18n();
    const bannerData = ref({} as any);

    const request = {
        locale: [ locale.value ],
        populate: {
            image: {
                fields: ['*']
            },
        },
    };

    const loadBannerData = async () => {
        return await $fetch(`/api/strapi/banner`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                request
            })
        });
    }

    useHead({
        titleTemplate: '%s - The Political Room',
    })

    const showModal = async (updatedAt: any) => {
        const { attributes } = await loadBannerData();
            
        bannerData.value = attributes;

        if(bannerData.value.active) {
            var modal = new tingle.modal({
                footer: true,
                stickyFooter: false,
                closeMethods: ['button'],
                closeLabel: "Cerrar",
                cssClass: [],
                onOpen: function() {

                },
                onClose: function() {
                    localStorage.setItem("bannerShowedAt", new Date(updatedAt).setDate(new Date(updatedAt).getDate() + 7).toString());
                    localStorage.setItem("bannerUpdatedAt", new Date(updatedAt).getTime().toString());
                },
                beforeClose: function() {
                    return true; 
                }
            });

            modal.setContent(`
                
                ${
                    bannerData.value.image.data ? `
                        <div style="text-align: center;">
                            <img style="width:100%" src="${bannerData.value.image.data.attributes.url}">
                        </div>
                    ` : `
                        <h1>${bannerData.value.title}</h1>
                        ${bannerData.value.content}
                    `
                }
                
            `)

            modal.open();

        } 
    }

    onMounted(async () => {

        const bannerShowedAt = localStorage.getItem("bannerShowedAt");
        const bannerUpdatedAt = localStorage.getItem("bannerUpdatedAt");

        const { attributes: { updatedAt } }: any = await loadBannerData();

        // Comprobamos si existe la variable de que ha visto el banner, que no han pasado 7 días y que la fecha de actualizacion del banner 
        if (!bannerShowedAt) {
            showModal(updatedAt);
        } else {
            if ( (bannerUpdatedAt && (parseInt(bannerUpdatedAt) < (new Date(updatedAt).getTime()))) ) {
                showModal(updatedAt);
            }
        }
    });
</script>


<template>
    <div class="global-wrap">
        <div class="section-content-wrap">
            <Header />
            <slot />
        </div>

        <Footer />

    </div>
</template>