<script setup lang="ts">
import Social from "./Social.vue";

const localePath = useLocalePath();
const {currentRoute} = useRouter();
const routeData = computed(() => currentRoute.value);
</script>

<template>
    <footer class="section-footer">
        <div class="footer-wrap wrap flex">
            <div class="footer-logo flex">
                <NuxtLink :to="localePath({ name: 'index' })">
                    <img src="/logo.svg" alt="Logo TPR">
                </NuxtLink>
            </div>
            <div class="footer-nav">
                <ul class="nav-list">
                    <li class="nav-list-item">
                        <NuxtLink :to="localePath({ name: 'privacyPolicy' })" class="nav-link">
                            {{ $t('settings.headerMenu.privacyPolicy') }}
                        </NuxtLink>
                        <span class="nav-dot nav-dot-current"
                              v-if="routeData.name === 'privacyPolicy___es' || routeData.name === 'privacyPolicy___en'"></span>
                    </li>
                    <span class="footer-tabs">|</span>
                    <li class="nav-list-item">
                        <NuxtLink :to="localePath({ name: 'cookiesPolicy' })" class="nav-link">
                            {{ $t('settings.headerMenu.cookiesPolicy') }}
                        </NuxtLink>
                        <span class="nav-dot nav-dot-current"
                              v-if="routeData.name === 'cookiesPolicy___es' || routeData.name === 'cookiesPolicy___en'"></span>
                    </li>
                    <span class="footer-tabs">|</span>
                    <li class="nav-list-item">
                        <NuxtLink :to="localePath({ name: 'legalAdvice' })" class="nav-link">
                            {{ $t('settings.headerMenu.legalAdvice') }}
                        </NuxtLink>
                        <span class="nav-dot nav-dot-current"
                              v-if="routeData.name === 'legalAdvice___es' || routeData.name === 'legalAdvice___en'"></span>
                    </li>
                </ul>
            </div>
            <Social/>
        </div>
        <div class="footer-copyright">
            © 2023
            <NuxtLink :to="localePath({ name: 'index' })">The Political Room</NuxtLink>
            . Todos los derechos reservados.
            Desarrollado por alvarogar.dev.
        </div>
    </footer>
</template>

<style>

@media (max-width: 500px) {
    .footer-tabs {
        display: none;
    }
}

.footer-nav {
    font-size: 15px;
    position: relative;
    letter-spacing: .9px;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 70%;
    flex: 0 1 70%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.footer-logo, .footer-social-links {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 15%;
    flex: 0 0 15%;
    line-height: 1;
}

.footer-logo img {
    max-width: 100%;
    max-height: 35px;
}

</style>